<template>
  <VueFinalModal
    style="display: flex; justify-content: center; align-items: center"
    :click-to-close="false"
  >
    <div class="end-of-subscription-container scrollbar" style="width: 950px; height: 730px">
      <div class="eosc-header">
        <img v-if="brands.isClementine()" src="~/assets/svg/clementine_icon.svg" >
        <img v-else-if="brands.isPourcentage()" src="~/assets/svg/pourcentage_icon.svg" >
        <img v-else-if="brands.isComptalib()" src="~/assets/svg/comptalib_icon.svg" >

        <div class="uds-headers">Votre période d’essai est terminée.</div>
        <div class="eosc-closeCross" @click="$emit('cancel')">
          <uds-icon color="#A0A1A6" icon-name="close" size="medium" />
        </div>
      </div>

      <div class="image-container" :class="{ '--is-comptalib': brands.isComptalib() }">
        <img v-if="brands.isComptalib()" src="~/assets/img/comptalibDashboard.png" >
        <SvgDashboardSvg v-else />
        <div class="uds-paragraph no-panic">
          Pas de panique, il n’est pas trop tard pour passer à l’étape supérieure et continuer de
          bénéficier de toutes nos fonctionnalités pour simplifier votre comptabilité.
        </div>
      </div>

      <div class="features-list">
        <div
          v-for="(feature, index) in features"
          :key="`feature-${index}`"
          class="feature uds-shadow-s"
        >
          <div class="feature-content">
            <div class="feature-header">
              <img v-if="brands.isComptalib()" :src="feature.icon" width="30px" >
              <uds-icon
                v-else
                :color="getPrimaryColors().primary500"
                :icon-name="feature.icon"
                size="medium"
              />
              <div class="uds-headers --h3">{{ feature.label }}</div>
            </div>
            <div>
              <div class="uds-paragraph --small">{{ feature.description }}</div>
            </div>
          </div>
          <div class="feature-status">
            <uds-icon
              v-if="feature.isLocked"
              :color="udsColors.udsNeutral700"
              icon-name="lock"
              size="medium"
            />
            <uds-icon
              v-else
              :color="getPrimaryColors().primary500"
              icon-name="check_circle"
              size="medium"
            />
            <uds-tag v-if="feature.isLocked" color="neutral">Payant</uds-tag>
            <uds-tag v-else color="primary">Gratuit</uds-tag>
          </div>
        </div>
      </div>

      <div v-if="!hasAskForContact" class="cta-container">
        <uds-main-button outlined size="medium" :loading="isLoading" @click="createContract()"
          >Demander un devis</uds-main-button
        >
        <div class="uds-paragraph cursor-pointer" @click="$emit('cancel')">
          Continuer avec la version gratuite
        </div>
      </div>
      <div v-else class="cta-infos-container">
        <uds-icon :color="udsColors.udsSuccess500" icon-name="check_circle" size="medium" />
        <span
          >Votre demande de devis a bien été envoyée.<br >Vous serez contacté dans les plus brefs
          délais.</span
        >
      </div>
    </div>
  </VueFinalModal>
</template>

<script setup lang="ts">
import { VueFinalModal } from "vue-final-modal";
import { filename } from "pathe/utils";

defineEmits(["cancel", "close"]);

const dayjs = useDayjs();
const userStore = useUsersStore();
const brands = useBrandsComposable();
const udsColors = getUdsColors();

const isLoading = ref(false);

const hasAskForContact = computed<boolean>(() => {
  return (
    userStore.contactRequests.filter(
      (cr) =>
        cr.idSource === 100 && cr.createdAt && dayjs().subtract(3, "months").isBefore(cr.createdAt),
    ).length > 0
  );
});

async function createContract() {
  try {
    isLoading.value = true;
    await $silex().customerActivity.createContactRequests(userStore.user!.id!, { idSource: 100 });
    await userStore.fetchContactHistory();
  } catch (error) {
    $notifier().open({ content: apiErrorToString(error) });
  }
  isLoading.value = false;
}

const glob = import.meta.glob("@/assets/img/*.png", { eager: true });
const images = Object.fromEntries(
  // @ts-expect-error
  Object.entries(glob).map(([key, value]) => [filename(key), value.default]),
);

const features = [
  {
    icon: brands.isComptalib() ? images.coins : "description",
    label: "Ventes",
    description: `${brands.getBrandName()} vous offre un outil tout-en-un pour créer vos factures, devis et bons de commande.`,
    isLocked: false,
  },
  {
    icon: brands.isComptalib() ? images.chart : "assessment",
    label: "Performances",
    description:
      "Nous vous aidons à piloter votre activité grâce à des indicateurs de performance en temps réel.",
    isLocked: true,
  },
  {
    icon: brands.isComptalib() ? images.chartPie : "account_balance",
    label: "Banques",
    description:
      "Notre application synchronise vos comptes et annote intelligemment vos opérations bancaires.",
    isLocked: true,
  },
  {
    icon: brands.isComptalib() ? images.balancesheet : "assignment",
    label: "Bilan comptable",
    description:
      "Bilan comptable, TVA, impôt sur les sociétés… Vous êtes notifié pour toutes vos déclarations.",
    isLocked: true,
  },
];
</script>

<style lang="scss" scoped>
.end-of-subscription-container {
  background: linear-gradient(180deg, $uds-primary-100 0%, $uds-white 63.87%);
  max-height: inherit;
  border-radius: 5px;

  .no-panic {
    color: $uds-neutral-800;
  }

  .image-container {
    width: 65%;
    margin: auto;
    position: relative;
    text-align: center;
    &.--is-comptalib img {
      max-width: 65%;
    }
    img {
      max-width: 100%;
    }
    .ds-paragraph {
      color: $uds-neutral-800;
    }
  }

  .eosc-header {
    padding-top: $uds-spacing-4;
    position: relative;
    display: block;
    img {
      position: absolute;
      left: $uds-spacing-4;
    }
    .uds-headers {
      text-align: center;
    }

    .eosc-closeCross {
      position: absolute;
      top: $uds-spacing-3;
      right: $uds-spacing-3;
      cursor: pointer;
    }
  }

  .features-list {
    display: flex;
    margin-left: 60px;
    margin-right: 60px;
    flex-wrap: wrap;
    gap: $uds-spacing-2;
    margin-top: $uds-spacing-4;
    .feature {
      width: calc(50% - 32px);
      background-color: $uds-white;
      border-radius: $uds-radius-2;
      padding: $uds-spacing-1_5;
      display: flex;
      align-items: center;

      .feature-content {
        display: flex;
        flex-direction: column;
        padding-right: $uds-spacing-0_5;
        .uds-paragraph.--small {
          color: $uds-neutral-800;
        }
      }
      .feature-status {
        display: grid;
        gap: $uds-spacing-0_5;
        place-items: center;
      }
      .feature-header {
        display: flex;
        margin-bottom: $uds-spacing-1;
        align-items: center;
        gap: $uds-spacing-1;
      }
    }
  }

  .cta-container {
    text-align: center;
    margin-top: $uds-spacing-4;
    .uds-paragraph {
      color: $uds-neutral-800;
      margin-top: 18px;
      padding-bottom: 20px;
    }
  }

  .cta-infos-container {
    font-size: $uds-spacing-2;
    line-height: 140%;
    display: grid;
    place-items: center;
    text-align: center;
    margin: $uds-spacing-4 0;
    span {
      margin: $uds-spacing-1 0;
    }
  }
}
</style>
