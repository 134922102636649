<template>
  <div/>
</template>

<script setup lang="ts">
import { useModal } from "vue-final-modal";
import Restricted from "~/components/commons/modals/Restricted.vue";

const subscriptionStore = useSubscriptionStore();

const isDialogOpened = computed(() => subscriptionStore.isRestricted);

watch(
  () => isDialogOpened.value,
  () => (isDialogOpened.value ? show() : null),
);

async function show() {
  const { open, close } = useModal({
    component: Restricted,
    attrs: {
      onCancel() {
        close();
        subscriptionStore.isRestricted = false;
      },
      onClose() {
        close();
        subscriptionStore.isRestricted = false;
      },
    },
  });
  await open();
}

onMounted(() => (isDialogOpened.value ? show() : null));
</script>
